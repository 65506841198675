import React from "react"
import Layout from "../components/layout"

const errorPage404 = () => {
  return (
  <Layout>
    <div className="container">
      <p>Oups, page non disponible !</p>
    </div>
  </Layout>
  )
}

export default errorPage404